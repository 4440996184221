import React, { useContext } from 'react';
import { AwesomeButton, AwesomeButtonSocial } from 'react-awesome-button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { PortfolioContext } from '../../context/PortfolioContext';
import Offcanvas from 'react-bootstrap/Offcanvas';
import stackLogo from './assets/so.png';
import './NavBar.css';
import 'react-awesome-button/dist/themes/theme-rickiest.css';

const NavBar = ({ selectedTab, setSelectedTab, featureFlag }) => {
	return (
		<Navbar
			id="portfolio-navbar"
			variant="dark"
			expand={false}
			collapseOnSelect
			sticky="top"
			className="portfolio-navbar"
		>
			<Container fluid>
				<Navbar.Toggle className="d-flex d-sm-none navbar-toggle">
					<NavBarToggleButton />
				</Navbar.Toggle>
				<Nav className="d-none d-sm-flex navBarLinksContainer">
					<NavBarTabButtons
						featureFlag={featureFlag}
						tabKey="home"
						selected={selectedTab}
						setSelectedTab={setSelectedTab}
					/>
					<NavBarTabButtons
						featureFlag={featureFlag}
						tabKey="experience"
						selected={selectedTab}
						setSelectedTab={setSelectedTab}
					/>
					<NavBarTabButtons
						featureFlag={featureFlag}
						tabKey="awards"
						selected={selectedTab}
						setSelectedTab={setSelectedTab}
					/>
				</Nav>
				<Nav className="d-none d-sm-flex navbarSocialIconsContainer">
					<SocialNavItem type="stackoverflow" size="icon" />
					<SocialNavItem type="linkedin" size="icon" />
					<SocialNavItem type="github" size="icon" />
					<SocialNavItem type="mail" size="icon" />
					<div>
						<ThemeToggle className="d-none d-sm-flex verticalLine" />
					</div>
				</Nav>
				<ThemeToggle className="d-flex d-sm-none" />
				<Navbar.Offcanvas id="offcanvasNavbar" placement="start">
					<Offcanvas.Body className="d-flex d-sm-none offcanvasBody">
						<Nav>
							<OffCanvasNavItem
								featureFlag={featureFlag}
								type="stackoverflow"
								className="offCanvasNavItem"
								tabKey="experience"
								selected={selectedTab}
								setSelectedTab={setSelectedTab}
							/>
							<OffCanvasNavItem
								featureFlag={featureFlag}
								tabKey="awards"
								selected={selectedTab}
								setSelectedTab={setSelectedTab}
								className="offCanvasNavItem"
							/>
							<hr />
							<OffCanvasNavItem
								type="stackoverflow"
								className="offCanvasNavItem"
							/>
							<OffCanvasNavItem type="linkedin" className="offCanvasNavItem" />
							<OffCanvasNavItem type="github" className="offCanvasNavItem" />
							<OffCanvasNavItem type="mail" className="offCanvasNavItem" />
						</Nav>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	);
};

const ThemeToggle = ({ className }) => {
	const { cursorChangeHandler, theme, themeChangeHandler } =
		useContext(PortfolioContext);
	return (
		<Nav.Item
			className={className}
			onMouseEnter={() => cursorChangeHandler('hovered')}
			onMouseLeave={() => cursorChangeHandler('')}
		>
			<AwesomeButton
				size="icon"
				type={!theme ? 'link' : 'secondary'}
				onPress={() => themeChangeHandler(!theme)}
			>
				<i className="fas fa-lightbulb"></i>
			</AwesomeButton>
		</Nav.Item>
	);
};

const NavBarToggleButton = () => {
	return (
		<div className="navbar-toggle">
			<AwesomeButton size="icon" type="secondary">
				<i className="fas fa-bars"></i>
			</AwesomeButton>
		</div>
	);
};

const NavBarTabButtons = ({
	tabKey,
	selected,
	setSelectedTab,
	style,
	featureFlag,
}) => {
	const { cursorChangeHandler, theme } = useContext(PortfolioContext);
	if (!featureFlag[tabKey]) {
		return '';
	}
	const getButtonType = () => {
		return selected === tabKey ? 'secondary' : theme ? 'link' : 'primary';
	};
	return (
		<Nav.Item
			style={style}
			className="navbarLinks"
			onMouseEnter={() => cursorChangeHandler('hovered')}
			onMouseLeave={() => cursorChangeHandler('')}
		>
			{/* <Nav.Link className="navbarLinks"> */}
			<AwesomeButton
				type={getButtonType()}
				size="small"
				// cssModule={bojack}
				onPress={() => {
					setSelectedTab(tabKey);
				}}
			>
				{tabHeaders[tabKey]}
				{/* </Nav.Link> */}
			</AwesomeButton>
		</Nav.Item>
	);
};

const SocialNavItem = ({ type, style }) => {
	const { cursorChangeHandler } = useContext(PortfolioContext);

	return (
		<Nav.Item
			onMouseEnter={() => cursorChangeHandler('hovered')}
			onMouseLeave={() => cursorChangeHandler('')}
		>
			<div className="d-none d-sm-flex navbarSocialIcons">
				<SocialButton type={type} size={'icon'} />
			</div>
		</Nav.Item>
	);
};

const OffCanvasNavItem = ({
	featureFlag,
	type,
	className,
	tabKey,
	selected,
	setSelectedTab,
}) => {
	if (tabKey && !featureFlag[tabKey]) {
		return;
	}
	const size = 'medium';
	return (
		<Nav.Item className={className}>
			{tabKey ? (
				<AwesomeButton
					type={selected === tabKey ? 'secondary' : 'primary'}
					size={size}
					onPress={() => {
						setSelectedTab(tabKey);
					}}
				>
					{tabHeaders[tabKey]}
				</AwesomeButton>
			) : (
				<SocialButton type={type} size={size} />
			)}
		</Nav.Item>
	);
};

const SocialButton = ({ type, size }) => {
	const { cursorChangeHandler } = useContext(PortfolioContext);

	const props = {
		size: size,
		target: '_blank',
		href: hrefMapping[type],
		type: type === 'stackoverflow' ? 'secondary' : type,
	};
	if (type === 'stackoverflow') {
		return (
			<div
				className="stackOverflowButton"
				onMouseEnter={() => cursorChangeHandler('hovered')}
				onMouseLeave={() => cursorChangeHandler('')}
			>
				<AwesomeButton {...props}>
					{size === 'icon' ? (
						<i className="fab fa-stack-overflow"></i>
					) : (
						<img src={stackLogo} width="100" height="22" alt="type" />
					)}
				</AwesomeButton>
			</div>
		);
	} else {
		return (
			<AwesomeButtonSocial {...props}>
				{size === 'icon' ? '' : headerMapping[type]}
			</AwesomeButtonSocial>
		);
	}
};

const headerMapping = {
	github: 'Github',
	linkedin: 'LinkedIn',
	mail: 'Mail',
};

const hrefMapping = {
	github: 'https://github.com/aneeshukla',
	linkedin: 'https://www.linkedin.com/in/aneeshukla/',
	mail: 'mailto:aneeshshukla@outlook.com',
	stackoverflow: 'https://stackoverflow.com/users/14007838/aneesh',
};

const tabHeaders = {
	experience: 'Experience',
	awards: 'Awards',
	home: 'Home',
};

export default NavBar;
