import React, { useContext } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { AwesomeButton } from 'react-awesome-button';
import { PortfolioContext } from '../../context/PortfolioContext';

const URL =
	'https://drive.google.com/file/d/1hS9E33heq3JCKHNZ2q_BEqBnGTuUYm1k/view?usp=sharing';

export const Toolbar = () => {
	const { cursorChangeHandler, theme } = useContext(PortfolioContext);
	return (
		<ButtonToolbar
			className="justify-content-between"
			style={{ marginBottom: '0.5rem' }}
		>
			<ButtonGroup size="sm"></ButtonGroup>
			<ButtonGroup
				size="sm"
				className="align-middle align-items-center"
				onMouseEnter={() => cursorChangeHandler('hovered')}
				onMouseLeave={() => cursorChangeHandler('')}
			>
				<AwesomeButton
					type={theme ? 'link' : 'primary'}
					className="downloadButton"
					size="medium"
					onPress={() => {
						window.open(URL, '_blank');
					}}
				>
					<i className="fas fa-download" />
					Resume
				</AwesomeButton>
			</ButtonGroup>
		</ButtonToolbar>
	);
};

export default Toolbar;
