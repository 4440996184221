import React, { useContext } from 'react';
import classNames from 'classnames';

import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ContentToolbar from './Toolbar';
import { PortfolioContext } from '../../context/PortfolioContext';

import sapLogo from './assets/sap-blue-logo.svg';
import pitneyLogo from './assets/pitney.png';

import './Experience.css';

const Experience = ({ className }) => {
	const { theme } = useContext(PortfolioContext);
	return (
		<Container className={className}>
			<ContentToolbar />
			<Card
				className={classNames('experienceCard', 'card-color', { dark: theme })}
				bg="warning"
			>
				<Card.Header as="h2">
					<div className="d-flex justify-content-between align-items-center">
						<CompanyLogoHeader
							img={sapLogo}
							className="SAPlogo"
							name="SAP"
							href={experienceData.sap.href}
						/>
						<div className="dateHeader">April,2021 - Present</div>
					</div>
				</Card.Header>
				<Card.Body>
					{experienceData.sap.data.map((experience, index) => {
						return (
							<ExperienceDescription
								key={experience.header + index}
								experience={experience}
							/>
						);
					})}
				</Card.Body>
			</Card>
			<Card
				className={classNames('experienceCard', 'card-color', { dark: theme })}
				bg="warning"
			>
				<Card.Header as="h2">
					<div className="d-flex justify-content-between align-items-center">
						<CompanyLogoHeader
							img={pitneyLogo}
							className="PitneyLogo"
							name="Pitney Bowes"
							href={experienceData.pitney.href}
						/>
						<div className="dateHeader">April 2018 - July 2019</div>
					</div>
				</Card.Header>
				<Card.Body>
					<ExperienceDescription experience={experienceData.pitney.data[0]} />
				</Card.Body>
			</Card>
		</Container>
	);
};

const ExperienceDescription = ({ experience }) => {
	return (
		<React.Fragment key={experience.header}>
			<Card.Title>{experience.header}</Card.Title>
			<Card.Text>{experience.body}</Card.Text>
		</React.Fragment>
	);
};

const CompanyLogoHeader = ({ img, name, className, href }) => {
	const { cursorChangeHandler } = useContext(PortfolioContext);
	return (
		<div
			onMouseEnter={() => cursorChangeHandler('hovered')}
			onMouseLeave={() => cursorChangeHandler('')}
		>
			<img
				src={img}
				className={className}
				alt={name}
				onClick={() => window.open(href, '_blank')}
			></img>
		</div>
	);
};

const experienceData = {
	sap: {
		href: 'https://www.linkedin.com/company/saplabsindia',
		data: [
			{
				header: 'Associate Developer – SAP Analytics Cloud, OEM Integration',
				body: (
					<ul>
						<li>
							Developed new features for a Java/Spring microservice,
							maintenance, and bug fixes. Also, involved working on JavaScript
							and Typescript for front-end features
						</li>
						<li>
							Designed and developed a solution (MERN stack) that improved
							development experience by helping the team to list, plan and
							retrospect their scrum activities, collating the information from
							different sources using JIRA API. Consistently delivered features
							and improvements based on the identified needs of the team to
							improve overall efficiency
						</li>
						<li>
							Designed and developed a solution (React.js, Node.js) to
							automatically generate and display documentation for features and
							fixes for various releases of the team product
						</li>
					</ul>
				),
			},
			{
				header: 'Scholar Developer – SAP API Management',
				body: (
					<ul>
						<li>
							Coordinated with a team composed of members from USA, Brazil,
							Germany, India and Australia to design and develop a Global Job
							Profiles website to be used globally by all SAP employees
						</li>
						<li>
							Added support for Apigee Load Balancer into APIM Design Time to
							allow APIM users to configure load balancers
						</li>
						<li>
							Developed and maintained a standalone API Designer based on the
							Swagger Editor which was later embedded into the SAP API Portal to
							allow creation and editing of APIs as per the OpenAPI
							Specification
						</li>
					</ul>
				),
			},
		],
	},
	pitney: {
		href: 'https://www.linkedin.com/company/pitney-bowes/',
		data: [
			{
				header: 'Intern',
				body: (
					<ul>
						<li>
							Developed front-end for a Support Portal required by the Global
							FInancial Services team using Angular 7
						</li>
						<li>
							Developed On-boarded User Dashboard Proof Of Concept (POC) using
							Angular, Node.js, Express.js and DynamoDB
						</li>
						<li>
							Developed Address Import POC using AngularJS, AWS(API Gateway,
							Lambda, S3, DynamoDB) and Node.js
						</li>
						<li>
							Developed REST API middleware between an android application and
							Freshdesk REST APIs with DynamoDB
						</li>
					</ul>
				),
			},
		],
	},
};

export default Experience;
