import React, { createContext, useState } from 'react';
export const PortfolioContext = createContext({
	isShown: true,
	cursorType: '',
	cursorChangeHandler: () => {},
	cursorHideHandler: () => {},
});
const PortfolioContextProvider = (props) => {
	const [cursorType, setCursorType] = useState('');
	const [theme, setTheme] = useState(false);
	const [isShown, setIsShown] = useState(false);
	const cursorChangeHandler = (cursorType) => {
		setCursorType(cursorType);
	};
	const cursorHideHandler = (isShown) => {
		setIsShown(isShown);
	};
	const themeChangeHandler = (isDark) => {
		setTheme(isDark);
	};
	return (
		<PortfolioContext.Provider
			value={{
				cursorType: cursorType,
				isShown: isShown,
				theme: theme,
				cursorChangeHandler: cursorChangeHandler,
				cursorHideHandler: cursorHideHandler,
				themeChangeHandler: themeChangeHandler,
			}}
		>
			{props.children}
		</PortfolioContext.Provider>
	);
};
export default PortfolioContextProvider;
