import React, { useContext } from 'react';
import useMousePosition from '../../hooks/useMousePosition';
import { PortfolioContext } from '../../context/PortfolioContext';

import './Cursor.scss';

const Cursor = () => {
	const { cursorType, isShown } = useContext(PortfolioContext);
	const { x, y } = useMousePosition();
	return isShown ? (
		<>
			<div
				className={`ring ${cursorType}`}
				style={{ left: `${x}px`, top: `${y}px` }}
			></div>
			<div
				className={`dot ${cursorType}`}
				style={{ left: `${x}px`, top: `${y}px` }}
			></div>
		</>
	) : (
		''
	);
};
export default Cursor;
