import React from 'react';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import './Awards.css';

const imageFolder = './assets/';

const Awards = ({ className }) => {
	return (
		<Container className={className}>
			<Carousel
				as={Card}
				className="d-none d-sm-block awards-carousel"
				variant="dark"
				wrap
				touch
				slide
			>
				{imagesDesktop.map((img) => {
					return (
						<Carousel.Item key={img}>
							<Image
								className="d-none d-sm-block w-100"
								alt="First slide"
								src={require(imageFolder + img + '.png').default}
								rounded
							/>
						</Carousel.Item>
					);
				})}
			</Carousel>
			<Carousel
				as={Card}
				className="d-block d-sm-none awards-card"
				variant="dark"
				wrap
				touch
				slide
			>
				{imagesMobile.map((img) => {
					return (
						<Carousel.Item key={img}>
							<Image
								className="d-block d-sm-none mobile-image"
								alt="First slide"
								src={require(imageFolder + img + '.png').default}
								rounded
							/>
						</Carousel.Item>
					);
				})}
			</Carousel>
		</Container>
	);
};

const imagesDesktop = [
	'1010',
	'19',
	'18',
	'17',
	'16',
	'14',
	'15',
	'13',
	'22',
	'11',
];
const imagesMobile = ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1'];

export default Awards;
