import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

import './Home.css';
import SO_LOGO from './so.png';

// const SO_URL =
// 	'https://api.stackexchange.com/2.2/users/14007838?site=stackoverflow';

const Home = ({ className }) => {
	return (
		<Container className={className}>
			<Row>
				<Col>
					<StackOverflowOverview />
				</Col>
			</Row>
		</Container>
	);
};

const StackOverflowOverview = () => {
	const [userData, setUserData] = useState(null);

	useEffect(() => {
		const getUserData = async () => {
			return {
				items: [
					{
						badge_counts: {
							bronze: 12,
							silver: 1,
							gold: 0,
						},
						account_id: 19174935,
						is_employee: false,
						last_modified_date: 1651926000,
						last_access_date: 1651952234,
						reputation_change_year: 1114,
						reputation_change_quarter: 1114,
						reputation_change_month: 1114,
						reputation_change_week: 1114,
						reputation_change_day: 67,
						reputation: 1225,
						creation_date: 1595919632,
						user_type: 'registered',
						user_id: 14007838,
						location: 'Remote',
						website_url: '',
						link: 'https://stackoverflow.com/users/14007838/aneesh',
						profile_image:
							'https://www.gravatar.com/avatar/debc6a49e84100f942ec52f9a9e631c5?s=256&d=identicon&r=PG&f=1',
						display_name: 'Aneesh',
					},
				],
				has_more: false,
				quota_max: 300,
				quota_remaining: 249,
			}; // await fetch(SO_URL).then((res) => res.json());
		};
		getUserData().then((profileData) => {
			setUserData(profileData.items[0]);
		});
	}, [userData]);

	if (!userData) {
		return '';
	}
	return (
		<Card className="overviewCard">
			<Card.Img variant="top" src={SO_LOGO} className="headerImage" />
			<Card.Body>
				<Card.Title></Card.Title>
				<Card.Text>Reputation: {userData.reputation}</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default Home;
