import './App.css';
import PortfolioContainer from './components/PortfolioContainer/PortfolioContainer';
import Cursor from './components/Cursor/Cursor';
import PortfolioContextProvider from './context/PortfolioContext';
// this is the reason bootstrap npm is required
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

function App() {
	return (
		<div className="App">
			<PortfolioContextProvider>
				<Cursor />
				<PortfolioContainer />
			</PortfolioContextProvider>
		</div>
	);
}

export default App;
