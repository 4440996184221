import React, { useState, useContext, useEffect } from 'react';

import NavBar from '../NavBar/NavBar';
import Content from '../Content/Content';
import classNames from 'classnames';

import { PortfolioContext } from '../../context/PortfolioContext';
import './PortfolioContainer.css';

const featureFlag = {
	home: false,
	experience: true,
	awards: true,
};

const PortfolioContainer = () => {
	const { cursorHideHandler, theme } = useContext(PortfolioContext);

	const [selectedTab, setSelectedTab] = useState(
		localStorage.getItem('activeTab') || 'experience'
	);

	useEffect(() => {
		localStorage.setItem('activeTab', selectedTab);
	}, [selectedTab]);

	return (
		<div
			className={classNames('portfolioContainer', { dark: theme })}
			onMouseEnter={() => cursorHideHandler(true)}
			onMouseLeave={() => cursorHideHandler(false)}
		>
			<NavBar
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
				featureFlag={featureFlag}
			/>
			<Content selectedTab={selectedTab} featureFlag={featureFlag} />
		</div>
	);
};

export default PortfolioContainer;
