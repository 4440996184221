import React from 'react';
import Home from '../Home/Home';
import Experience from '../Experience/Experience';
import Awards from '../Awards/Awards';

import './Content.css';

const PotfolioContent = ({ selectedTab }) => {
	switch (selectedTab) {
		case 'home':
			return <Home className="contentCard" />;
		case 'experience':
			return <Experience className="contentCard" />;
		case 'awards':
			return <Awards className="contentCard awardsCard" />;
		default:
			return <Experience className="contentCard" />;
	}
};

export default PotfolioContent;
